<template>
  <div style="height: calc(100% - 10px);">
    <lb-stat-search key="CHOOSE_PRODUCT" tab="CHOOSE_PRODUCT" :search="search" @search="eventSearch" />
    <ml-table ref="TABLE" hand-request :search="search" :api="apiList" border>
      <template slot="columns">
        <el-table-column type="index" label="序号" width="55" />
        <el-table-column prop="month" label="月份" width="95">
          <template v-slot="{row:{month}}">
            {{ month|parseTime('{y}年{m}月') }}
          </template>
        </el-table-column>
        <el-table-column prop="brand_name" label="品牌" min-width="100">
          <template v-slot="{row:{brand_name}}">
            {{ brand_name || '-' }}
          </template>
        </el-table-column>
        <el-table-column prop="product_name" label="产品名称" min-width="300" />
        <el-table-column prop="num_choose" label="选品次数" min-width="100" align="right">
          <template v-slot="{row:{num_choose}}">
            <b> {{ num_choose|numberFormat }}</b>
          </template>
        </el-table-column>
        <el-table-column label="选品状态次数" min-width="300" align="center">
          <template v-slot="{row}">
            <div class="nums">
              <div><span>上播</span><b>{{ row.num_choose_up }} 次</b></div>
              <div><span>不上播</span><b>{{ row.num_choose_down }} 次</b></div>
              <div><span>红人试用</span><b>{{ row.num_choose_kol }} 次</b></div>
              <div><span>团队试用</span><b>{{ row.num_choose_team }} 次</b></div>
              <div><span>待定</span><b>{{ row.num_choose_wait }} 次</b></div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="leader_name" label="负责人" align="center" />
      </template>
    </ml-table>
  </div>
</template>
<script>

import { lbStatListByProduct } from '../../../../api/lb_stat'
import MlTable from '@/components/MlTable/index.vue'
import LbStatSearch from '../components/search.vue'

export default {
  name: 'LbStatTab1',
  components: { LbStatSearch, MlTable },
  data() {
    return {
      search: {}
    }
  },
  methods: {
    apiList: lbStatListByProduct,
    eventSearch: function(search) {
      this.search = search
      this.$nextTick(() => {
        this.$refs.TABLE.handleSearch()
      })
    }
  }
}
</script>
<style lang="scss">
.nums {
  //display: grid;
  grid-template-columns: repeat(2, calc((100% - 70px) / 2));
  //grid-gap: 10px;
  grid-gap: 0;

  div {
    display: flex;
    justify-content: space-between;
    line-height: 1.2;
    font-size: 12px;

    span, b {
      width: 50%;
      padding: 0 4px;
    }

    span {
      text-align: right;
      border-right: 1px solid #f2f2f2;
      color: #909399;
    }

    b {
      text-align: left;
    }
  }
}
</style>
